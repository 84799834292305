<template>
  <TTView>
    <VRow>
      <VCol>
        <RouteForm
          :entity="route"
          @update:name="route.name = $event"
          @update:env="route.env = $event"
          @update:addressOverride="route.addressOverride = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { DEFAULT_ROUTE } from '../../components/sysadmin/common';
import RouteForm from '../../components/sysadmin/RouteForm.vue';

export default {
  name: 'RoutesEdit',

  components: {
    RouteForm,
  },

  inject: ['Names'],

  data() {
    return {
      route: { ...DEFAULT_ROUTE },
      loading: false,
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    fetch() {
      this.loading = true;

      const { routeId: id } = this.$route.params;

      this.$di.api.Sysadmin
        .getRoute(id)
        .then((response) => {
          this.route = response;
        })
        .catch(this.$di.notify.errorHandler)
        .finally(() => {
          this.loading = false;
        });
    },

    handleSubmit() {
      this.loading = true;

      const { routeId: id } = this.$route.params;
      const { route: data } = this;

      delete data.id;

      this.$di.api.Sysadmin
        .updateRoute(data, id)
        .then(() => {
          this.$di.notify.snackSuccess('Маршрут обновлен');
          this.$router.push({
            name: this.Names.R_SYSADMIN_ROUTES,
          });
        })
        .catch(this.$di.notify.errorHandler)
        .finally(() => {
          this.loading = false;
        });
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
