/* eslint-disable import/prefer-default-export */

const DEFAULT_ROUTE = {
  id: null,
  name: null,
  env: null,
  addressOverride: null,
};

export { DEFAULT_ROUTE };
