<template>
  <VCard>
    <VCardTitle>
      {{ normalizedTitle }}
    </VCardTitle>

    <VCardText>
      <VForm>
        <VContainer fluid>
          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.name"
                label="Имя маршрута"
                placeholder="service"
                name="routeName"
                persistent-hint
                :hint="
                  `По этому имени происходит проксирование: https://sysadmin-base-url/proxy/${entity.name || 'service'}`
                "
                @input="$emit('update:name', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.env"
                label="Переменная окружения"
                placeholder="SERVICE_ADDRESS"
                name="routeEnv"
                persistent-hint
                hint="env"
                @input="$emit('update:env', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.addressOverride"
                label="Адресс сервиса"
                placeholder="http://service-address:3000"
                name="routeAddressOverride"
                persistent-hint
                hint="В кластере kubernetes"
                @input="$emit('update:addressOverride', $event)"
              />
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        @click="handleSubmit"
      >
        {{ normalizedSubmit }}
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
import { DEFAULT_ROUTE } from './common';

export default {
  name: 'RouteForm',

  props: {
    entity: {
      type: Object,
      default: () => ({ ...DEFAULT_ROUTE }),
    },
  },

  computed: {
    normalizedTitle() {
      let title = 'Создание маршрута';

      if (this.entity.id !== null && this.entity.id !== undefined) {
        title = 'Редактирование маршрута';
      }

      return title;
    },

    normalizedSubmit() {
      let title = 'Добавить';

      if (this.entity.id !== null && this.entity.id !== undefined) {
        title = 'Сохранить';
      }

      return title;
    },
  },

  methods: {
    handleCancel() {
      this.$emit('cancel');
    },

    handleSubmit() {
      this.$emit('submit', this.entity);
    },
  },
};
</script>
